<template>
  <v-container id="organizations" fluid tag="section">
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <base-material-stats-card
          color="primary"
          icon="mdi-check"
          title="Total Organizations"
          value="100"
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-row rows="12" align="end" justify="end">
          <div class="my-2">
            <template>
              <v-dialog
                v-model="dialog"
                max-width="900px"
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="9"
                    color="primary"
                    x-large
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>mdi-domain-plus</v-icon>
                    Create Organization
                  </v-btn>
                  <v-btn elevation="9" color="blue-grey" x-large>
                    <v-icon left>mdi-cloud-upload</v-icon>
                    Upload Organizations
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.lname"
                            label="Full Org Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.fname"
                            label="Short Org name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.phone"
                            label="Phone number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.email"
                            label="Email"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.email"
                            label="Invoicing Day"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close"> Cancel </v-btn>
                    <v-btn color="primary" text @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this item?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="primary" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </div>
        </v-row>
      </v-col>

      <v-col cols="12" md="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-dark lightgray--text">
              User List
              <button class="float-right">Download</button>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                color="lightgray"
              />
            </div>

            <div class="float-right white--text">
              <v-switch
                v-model="singleExpand"
                color="#ffffff"
                label="Single Expand"
                class="mt-2"
              ></v-switch>
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              item-key="id"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-expand
              class="elevation-1"
              @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
              @dblclick:row="editItem(item)"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize"> Reset </v-btn>
              </template>
              <template
                style="background-color: aliceblue"
                v-slot:expanded-item="{ headers, item }"
              >
                <td :colspan="headers.length">
                  <v-container>
                    <div id="expandedRow">
                      <v-header class="font-weight-regular">Details</v-header>
                      <div id="userDetails">
                        <v-row>
                          <label id="detailsLabel">
                            <span class="font-italic">Name: </span
                            ><span
                              class="font-weight-regular"
                              id="detailsValue"
                              >{{ item.name }}</span
                            >
                          </label>
                          <label id="detailsLabel">
                            <span class="font-italic">Email: </span
                            ><span
                              class="font-weight-regular"
                              id="detailsValue"
                              >{{ item.email }}</span
                            >
                          </label>
                          <label id="detailsLabel">
                            <span class="font-italic">Phone number: </span
                            ><span
                              class="font-weight-regular"
                              id="detailsValue"
                              >{{ item.phoneNumber }}</span
                            >
                          </label>
                          <label id="detailsLabel">
                            <span class="font-italic">Name: </span
                            ><span
                              class="font-weight-regular"
                              id="detailsValue"
                              >{{ item.name }}</span
                            >
                          </label>
                          <label id="detailsLabel">
                            <span class="font-italic">Name: </span
                            ><span
                              class="font-weight-regular"
                              id="detailsValue"
                              >{{ item.name }}</span
                            >
                          </label>
                          <label id="detailsLabel">
                            <span class="font-italic">Name: </span
                            ><span
                              class="font-weight-regular"
                              id="detailsValue"
                              >{{ item.name }}</span
                            >
                          </label>
                          <label id="detailsLabel">
                            <span class="font-italic">Name: </span
                            ><span
                              class="font-weight-regular"
                              id="detailsValue"
                              >{{ item.name }}</span
                            >
                          </label>
                          <label id="detailsLabel">
                            <span class="font-italic">Name: </span
                            ><span
                              class="font-weight-regular"
                              id="detailsValue"
                              >{{ item.name }}</span
                            >
                          </label>
                        </v-row>
                        <br />
                      </div>
                      <v-divider dark> </v-divider>
                      <v-header class="font-weight-regular">Documents</v-header>
                      <div id="documents">
                        <v-row>
                          <div id="documentImageSpan">
                            <v-img
                              id="docImg"
                              class="white--text align-end"
                              src="https://images.unsplash.com/photo-1585503873681-b355ba18d079?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=749&q=80"
                            >
                              <v-card-title>ID Front</v-card-title>
                            </v-img>
                          </div>
                          <div id="documentImageSpan">
                            <v-img
                              id="docImg"
                              class="white--text align-end"
                              src="https://images.unsplash.com/photo-1585503873681-b355ba18d079?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=749&q=80"
                            >
                              <v-card-title>ID Back</v-card-title>
                            </v-img>
                          </div>
                          <div id="documentImageSpan">
                            <v-img
                              id="docImg"
                              class="white--text align-end"
                              src="https://images.unsplash.com/photo-1624395213043-fa2e123b2656?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1974&q=80"
                            >
                              <v-card-title>Selfie</v-card-title>
                            </v-img>
                          </div>
                        </v-row>
                      </div>
                    </div>
                  </v-container>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    sortBy: "id",
    sortDesc: true,
    search: "",
    expanded: [],
    singleExpand: false,
    headers: [
      {
        sortable: true,
        text: "Created At",
        value: "createdAt",
        align: "left",
      },
      {
        sortable: false,
        text: "Invoice Day",
        value: "invoicingDay",
        align: "right",
      },
      {
        sortable: false,
        text: "Name",
        value: "name",
      },
      {
        sortable: true,
        text: "Alias",
        value: "shortName",
      },
      {
        sortable: true,
        text: "Interest(%)",
        value: "loanInterestRatePercentage",
      },
      {
        sortable: true,
        text: "Email",
        value: "email",
      },
      {
        sortable: true,
        text: "Phone",
        value: "phoneNumber",
      },
      { text: "Actions", value: "actions", sortable: false, align: "right" },
      { text: "", value: "data-table-expand" },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      createdAt: "",
      lname: "",
      fname: "",
      phone: "",
      email: "",
      organization: "",
    },
    defaultItem: {
      createdAt: "",
      lname: "",
      fname: "",
      phone: "",
      email: "",
      organization: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Create Organization"
        : "Edit Organization";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.items = [
        {
          id: 1,
          name: "Zisc Insurance",
          shortName: "ZISC",
          companyTPIN: "928372",
          loanInterestRatePercentage: "8",
          email: "billy@zisc.co.zm",
          phoneNumber: "+260987676888",
          invoicingDay: "23",
          createdAt: "2020/12/01 08:50:16",
        },
        {
          id: 2,
          name: "Royal Zambian Airlines",
          shortName: "RZL",
          companyTPIN: "194308",
          loanInterestRatePercentage: "8",
          email: "mqui@rzl.co.zm",
          phoneNumber: "+260211987324",
          invoicingDay: "14",
          createdAt: "2021/05/10 15:20:28",
        },
        {
          id: 3,
          name: "Orchard Stores Limited",
          shortName: "Orchard",
          companyTPIN: "837483",
          loanInterestRatePercentage: "8",
          email: "billy@zisc.co.zm",
          phoneNumber: "+260987676888",
          invoicingDay: "28",
          createdAt: "2020/12/01 09:43:42",
        },
        {
          id: 4,
          name: "Mkumbi Resorts Limited",
          shortName: "Mkumbi",
          companyTPIN: "474934",
          loanInterestRatePercentage: "8",
          email: "sam@mkumbiresorts.co.zm",
          phoneNumber: "+260760000678",
          invoicingDay: "11",
          createdAt: "2020/11/04 09:43:42",
        },
        {
          id: 5,
          name: "Grand Torque Cars",
          shortName: "GTC",
          companyTPIN: "736487",
          loanInterestRatePercentage: "8",
          email: "runya@gtc.com",
          phoneNumber: "+260762872839",
          invoicingDay: "11",
          createdAt: "2021/01/12 09:43:42",
        },
        {
          id: 6,
          name: "Pinewood Group",
          shortName: "Pinewood",
          companyTPIN: "820098",
          loanInterestRatePercentage: "8",
          email: "finance@pinewood.co.zm",
          phoneNumber: "+260762872839",
          invoicingDay: "11",
          createdAt: "2020/07/15 09:43:42",
        },
      ];
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.items.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
